import { useQuery } from 'react-query';

import { getUserAddresses } from './user-service';

export function useUserAddresses(options) {
  return useQuery(
    ['userAddresses'],

    () => getUserAddresses(),

    {
      placeholderData: {
        mailing: {},
        billing: {},
        rating: {},
      },

      ...options,
    }
  );
}
